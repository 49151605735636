import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import './assets/scss/style.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBox, faCheckCircle, faCircle, faMapMarker, faUserSecret, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import './registerServiceWorker'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import store from './store'
import axios from 'axios';
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta';

// axios.defaults.baseURL = 'http://192.168.1.3:8000/api/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

library.add(faMapMarker,faCircle, faBox, faCheckCircle, faUserSecret, faTriangleExclamation)

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(BootstrapVue)
.use(IconsPlugin)
.use(router)
.use(store)
.use(VueMeta)
.use(VueCookies, { expires: '1d'})
.mount('#app')
