//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    
    async submitMicrositeRecord({dispatch,rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'application/json',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/record',form, headers)
        return response
    },
    
    async createPaymentVA({dispatch,rootGetters}, form) {
        const headers =  {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        }

        const response = await axios.post('microsites/va',form, headers)
        return response
    },
    
    async detailMicrositeByCode({dispatch,rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('microsites/microsite',{ params: form, headers: headers})
        return response
    },
    
    async checkPaymentStatus({rootGetters}, form) {
        const headers =  {
                Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
            }
        
        const response = await axios.get('payment-gateway/check',{ params: form, headers: headers})
        return response
    },
};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
