

import { createStore } from 'vuex'

import createPersistedState from "vuex-persistedstate";
import voucher from './modules/voucher';
import microsite from './modules/microsite';


export default createStore({
  modules: {
    voucher,
    microsite
  },
  plugins: [createPersistedState()]
});
